import styled from "styled-components";

import Logo from "../assets/db88f9373625111ddc8c07d916c7c386_1956x562_408x809_2807x1985_crop 1.png";
import Triangle from "../assets/Triangle.svg";
import Triangle2 from "../assets/Triangle_2.svg";

import { motion } from "framer-motion";
import { Button, TextField } from "@mui/material";
import { Instagram } from "@mui/icons-material";

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
  
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  

const MaintenancePage = () => {

    return <StyledMaintenancePageContainer variants={container} initial="hidden" animate="visible">
        <StyledLeftTriangle src={Triangle} variants={item} />
        <StyledContentHolder>
        <motion.img src={Logo} style={{width: "90%", height: "auto"}}/>
        <StyledMaintenancePageContent>
            <StyledParagraph variants={item} >Unsere neue Website ist bald für Sie online.</StyledParagraph>
            <StyledParagraph variants={item}>Wir danken Ihnen für Ihre Geuld.</StyledParagraph>
        </StyledMaintenancePageContent>
        {/*<StyledMaintenancePageContactForm>
            <motion.h1 variants={item}>Kontakt</motion.h1>
            <StyledMaintenancePageContactFormWrapper variants={item} >
                <TextField label="Name" />
                <TextField label="E-Mail" />
            </StyledMaintenancePageContactFormWrapper>
            <StyledMaintenancePageContactFormWrapper variants={item} >
                <TextField label="Telefonnummer" />
                <TextField label="Wie haben Sie uns gefunden?" />
            </StyledMaintenancePageContactFormWrapper>
            <motion.div variants={item} style={{width: "100%"}}>
            <TextField label="Ihre Nachricht" multiline style={{width: "100%"}} maxRows={8} />
            </motion.div>
            <motion.div variants={item}>
            <StyledButton>Absenden</StyledButton>
            </motion.div>

        </StyledMaintenancePageContactForm>*/}
        <SocialContainer variants={item}>
            <StyledParagraph>Folge uns auf:</StyledParagraph>
            <SocialLink href="https://www.instagram.com/yumaksofficial/">
                <Instagram fontSize="large"/>
            </SocialLink>
        </SocialContainer>
        </StyledContentHolder>
        <StyledRightTriangle src={Triangle2} variants={item} />
    </StyledMaintenancePageContainer>
}

export default MaintenancePage;

const StyledMaintenancePageContainer = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100vw;
    gap: 10px;
    flex-direction: row;
    position: relative;
    @media (max-width: 1158px) {
       justify-content: center;
    }
`

const StyledContentHolder = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const StyledLeftTriangle = styled(motion.img)`
    @media (max-width: 1158px) {
       display: none;
    }
`

const StyledRightTriangle = styled(motion.img)`
    @media (max-width: 1158px) {
        display: none;
    }
`

const StyledMaintenancePageContent = styled.div`
    text-align: center;
`

const StyledParagraph = styled(motion.p)`
    font-size: 21px;
    font-weight: 300;
    margin: 0;
`

const StyledMaintenancePageContactForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
`

const StyledMaintenancePageContactFormWrapper = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
`

const StyledButtonHolder = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const StyledButton = styled.button`
    text-transform: capitalize;
    background-color: #1C4595;
    font-size: 20px;
    color: white;
    padding: 10px 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: ease 200ms;

    &:hover {
        transform: scale(1.1);
    }
`;

const SocialContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
`;

const SocialLink = styled.a`
    text-decoration: none;
    color: #1C4595;
`